export const soty = {
"0xbdddc071a8f11e178f0b7ef462c6667d8d7fd3e4":"0xe73cc12d991406f46208046f5b3ccc8e4dfcdc4cd781bfee5fa402a53ae5591653e62fcbbb704c9949dada179357e08bed72e9663b448fa47c8c4d61ceedec451b",
"0x66279c044a71ec047e1f9545629ee2db08f6e176":"0x0c1f366e2dfc64129322a4870075bf2fe4e5f369c52616373aec81763c879575507e38de1d05ed1ee23be0cc105356474a315313d12f1e0fa64c3633f618438e1c",
"0x876510755f5fc1e39e7174443b20965b837a9ad7":"0x3d6e0668307696d0afb5c83231e08ba9c0197e9833a9851b45d2ac9776f23b94632d1154e072dcdef199299b1994776bcdada7fa36957ef49c8fd7c188e517991c",
"0xbcaeb5ddadbb3ba2880b19d254e0cf5e23a68917":"0x16fcdead745a2d70f7d99c179221dddd1fe887c351a7fcbf64c13ec5317cafc36004a4a68a0d989ffa1669665b4f9628b2d8d99fdfe944fcc0b6dbf5d6c3ff891b",
"0x3860cab49b099cfb4f41c941bedc68b7d0b80490":"0x0417a70859febb918227e21a949da72a6e88bbccb2a5a3761b0577eb1686f69c04f1d502443e23be2890028652b61aaad896509c12279c93de4692f646a57b811b",
"0xa8601dc3785d060ff71e90791374bdb7e8bcf169":"0x11975dd8881d51f28e7ea4f33f5e32ed961bff3d2b4bfa403b3653b4a94b843002b9515feccacbbb372fe2904154c94459c0dbca428f0971fb15dc0852b7718d1b",
"0x53868b9377a3d6da5e7fae80d81bf2001f2c38e8":"0xc5bff2d9b3c318e45872faa9e861f89fa6740a34d554d95abeeb61886742aecb5c8609a714927e39f2f88f925e7dd770adba5e00d496a09886e2ad4b75119b871c",
"0x4a6cb72561623271a84490191f2429ebb611dd51":"0xe468a40685504860b53e64f2f3944306fc97e382665f2ca10bb8ed90791dc5b277fed472c29a268f110b9653c708c63bb9581b719e56d3c4f0158d3a4dee0fba1b",
"0xa25803ab86a327786bb59395fc0164d826b98298":"0x9adac02886df02903ff5592774a178e80679e06cbceb5f02f89f206510111c6a3a3fe4ae1e851170ae91afcc25d9332bc94866219fcd4a67b578656adc0512881b",
"0x50e28c1d7043971dca9e168622b065fa8061a138":"0x2c92817af6d6d13cf45aea22286232e09e4b9a73aa3236d1f6b7d66cb8ce212d29660df7bf4e8b53bc8332849517f02b8e66ab6a7c10355916dcc730ca47ff791b",
"0xb97d99e5580d59a83dfa7b4fce832f1c7be35959":"0x6c30b06fda3607d2b1362d7e48fab654481a91a3b8cee30b2689a9a70fed3f4b3273ecd19c8dc5ca0f495224cbc636cf14b17817de27e9f80604f7f1f358870c1c",
"0x4ab59d6cac15920b2f2909c0529995e12c509b80":"0x3f16f0d02ac5b72d3b05ef6c9a4b8b871e9225e0ac346d11a28c6d755bfa8b83645e6909bf3d8cc59eb1e1fb4051818c79c881c0215d4d6896b96d3713124c111c",
"0x2ef8f07465fa93ae4b0aa533b7718e7ce421f1cd":"0x9ba799af331008e1fb8c35fecb60de087ee8e5fa6940ca8157100f09face8b695e68f220cc8d938a139e00ac991b417432bd0987d103d7a013a5204997fcc4111c",
"0x1972dafbd8616e67f89fe2738c0253c14af1a7e8":"0x0f9099f685d3d88f8725f109800d7c238710f32adb6cc21ab7a9382b25dd3f0a498ca42e7b3d05f9ca1e70062289e19879cd2a69b4f4db8c6c5cfebe26ab07581c",
"0xa5bcd9cb4a870936ea1d8ba8f3ef9a8da80840a4":"0x80efbf213dd414927c0ee6a67a164c4e8c983b53df2c0dcd5adb41874d1637bd2baa2c8650f4dd0495f346120f46e5ddc17878f9c9060f3cf88fd934ebb2d8661b",
"0xa31f0e8f16f0e251362059fc63b92c60ecf9f0b0":"0xcb3f54ec99d7f98d67a12d39278b042a7706f494820322e4b702fccd1a5e53bf0f7048ae0f47695437bf90bad5ba65b6bf115a24630a3f8d67a93329d34de8b71c",
"0x7601e2afc46d6031d154d508bfc781b11a6d1bdf":"0x586e07bb14b720d2bc3328d4f89c69bc9a68f5e58cf9e9cf53e2874cd0dd921f3dd6ae7bf67c3a94d422ee89185d721c946189389698530172db6b7d8fc508f11c",
"0x8ca65600cb3073b8e68a56854223d9ec408c8878":"0x0d99f0f8c187f51386d07f24e8541f02010802707787d50b1b29da06c2189b907c5185cbe561c7af3e019ddca359f795b7e44fb512600ba4ab86b91cce2a482a1c",
"0x8ba5d2b3ff63794cfb9b1042f2bd6c5216280254":"0x62b54cb0529ac5218627edb97432f829b7e5fc6b7c8fa5d9ecf1ad8db8201fbc5adb2cf0188c924072c682a1203d6438867cc4feffc3e80755a59a7ba8ab896c1c",
"0xfae0240c9acd01bf6b8d1eb467bf7df58eb8c800":"0x727db009588dcc8722f0eac0a60b45178409ab3ee332e78ae3356ef76dfccc36276fc3d36e2786a747ff750c5899767ad5cf72e5abc53fb2cb002389cb2bebf81b",
"0x478bb542f7658d635abba67edb987806dff5b83d":"0x84247753883c18310927ee101521110dd773dcc11128e1fbc8711cc58e9ea5e3124d71f4053d2615cd1faa115eba10e011052dbe7065fdb1bfa4090fcc55db741b",
"0x6f4152b9eb3b626baea2f549c387c5bc31d857cb":"0x258d67692348098383095b4c17fbf04bdd28b8e07937908abea4ec9d82f940a003d22b58c35808750857c3731444dda0311d1b92fc391ff709fd17973b02c8fa1c",
"0x6812052946a2ff7a8803e1cd08e85d5171e5a0e5":"0x67dfaf0ab8575992d5064d47bf7b42c1f593d6712c5b8745b9b53fb7e132349f67a160cecf56a1714b4448f6a0643f55b883780f9264350a52f8a7f0a36237491c",
"0x6d37616117dc71758fb02b7ba97b6cdafd0a7037":"0xfa05c999cdd7a132d946f7b6d1fd4ce3d726f91c9946534da8e1110d1f855a5e6f78052a8c0aedd58159a7160d9c32b26412fb8a6531b8d092c1419bc08a1d541b",
"0xe9a2bcbd2feb38d81a29a820db0f1afe23a5ea62":"0x1b3d870d9e0e42ae5b995d954255cec3d92dc90f95f64090061951821a5b2e7b6a4fd81f6a4676932c2b2bfd609bc63ddce9b11900aa7ca52c27f95e0a9f8e431b",
"0x6df0d722fb13a0b517b40415d01d9ffe9a459df6":"0xd55d4790c20a7819a26d020a35eb91894cf31e4ee87dfc19ea29fe74752f602d2f1cf995aa4c4647aeee3f500ea4d64763977f5f24fc6c4885a3bb9c513049921c",
"0xb9474172313c82ae65244027ab912afe0c9cf1e5":"0xadf193cef3f1785c8e0eae54cfc2ab41233cfe1876695f42bae168013d88d5352fab1a9bef0252b82c5646ab5338f7a87ef3feec6fefd9bd0475b63ddd2c6bca1c",
"0x2349334b6c1ee1eaf11cbfad871570ccdf28440e":"0x200799a3e844ec9b84c90f6b9fdcd82905ffc9b7b53350113e792f07e6a50bb30d9d0679dc22402ad330d3231a4ef6dc9755777065b076f9450dfd3d6bcd84b11b",
}