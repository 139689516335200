import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';


import { ethers } from "ethers"

import axios from 'axios';
import { ConnectWallet } from "@thirdweb-dev/react";
import { useAddress, Web3Button, useContract, useContractWrite } from "@thirdweb-dev/react";

import { soty } from '../vouchers/soty.js';
import DelegateCashButton from 'delegatecash-button-react';

function App() {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }


  const address = useAddress();

  const [searchParams] = useSearchParams();
  const getAddress = useState(searchParams.get("address"));

  const myDirectory = useRef(null);

  /* global BigInt */

  const navigate = useNavigate();


  const [addr, setAddress] = useState('');
  const [avail, setAvailable] = useState(0);
  const [voucher, setVoucher] = useState(0);
  const [delegating, setDelegating] = useState(false);
  const [delegateWallet, setDelegateWallet] = useState('');


  const reload = () => {
    window.location.reload();
  }

  const [data, setData] = useState([]);
  const [clickedItems, setClickedItems] = useState([]);

  useEffect(() => {
    if (address) {
      loadTokens(address);
      getStats(address);
      document.getElementById("show-stats").style.display = null;
      document.getElementById("delegate").style.display = null;
    }
  }, [address]);


  useEffect(() => {

    if (clickedItems.length <= avail) {
      for (var i = 0; i < clickedItems.length; i++) {
        arrayToSend.push(clickedItems[i].token);
      }
    }
    else {
      document.getElementById("toomany").style.display = null
      document.getElementById("reload-load").style.display = "none"
      document.getElementById("reset").style.margin = 0;
    }

  }, [clickedItems]);

  const checkDelegate = (addr) => {
    getStats(addr)
    loadTokens(addr)
    arrayToSend = "";
    setClickedItems([])
    document.getElementById("reload-load").style.display = "none";
    document.getElementById("go-load").style.display = "none";
    if (addr.toLowerCase() !== address.toLowerCase()) {
      setDelegateWallet(addr);
      setDelegating(true);
    }
    else {
      setDelegating(false);
    }
  }


  var arrayToSend = [];

  const handleClick = async (item) => {

    document.getElementById("claim-eligible").style.display = "none";
    document.getElementById("reload-load").style.display = null;
    document.getElementById("go-load").style.display = null;

    const newData = data.map((d) => {
      if (d.id === item.id) {
        return { ...d, clicked: true };
      } else {
        return d;
      }
    });
    setData(newData);

    setClickedItems((prevClickedItems) => [...prevClickedItems, item]);


    // document.getElementById("load-token-array").innerHTML += "#" + item.id + ", ";

    if (clickedItems.length > 0) {

      document.getElementById("reload-load").innerHTML = "BURN " + (clickedItems.length + 1) + " TOKENS";

    }
    else {
      document.getElementById("reload-load").innerHTML = "BURN " + (clickedItems.length + 1) + " TOKEN";

    }


  };

  async function loadTokens(addr) {


    const collection = '0xAc5Aeb3b4Ac8797c2307320Ed00a84B869ab9333';

    const response = await axios.get(`https://api.reservoir.tools/users/${addr}/tokens/v10`, {
      params: {
        collection: [collection],
        limit: 200,
        sortBy: 'acquiredAt',
        sortDirection: 'desc'
      }
    });

    let tokens = response.data.tokens.map((item) => ({
      id: item.token.tokenId,
      token: item.token.tokenId,
      owner: addr,
      clicked: false
    }));

    if (tokens.length > 0) {
      var totalAssets = tokens.length;
      document.getElementById("total").innerHTML = totalAssets;
      document.getElementById("total").classList.remove("text-loading");
      setData(tokens);
    } else {
      document.getElementById("total").innerHTML = 0;
      document.getElementById("total").classList.remove("text-loading");
    }
  }


  const burnContract = {
    address: "0x95288c755982E32354c694a6DA001212c1dF0b9b",
    abi: [
      "function totalMintedRegular(address) public view returns (uint256)",
      "function mintedSOTY(address) public view returns (bool)",
      "function regularMinted() public view returns (uint256)",
      "function SOTYMinted() public view returns (uint256)"
    ]
  };

  const ogContract = {
    address: "0xAc5Aeb3b4Ac8797c2307320Ed00a84B869ab9333",
    abi: [
      "function balanceOf(address) public view returns (uint256)"
    ]
  };

  const { contract } = useContract(burnContract.address);
  const { mutateAsync: burnToken } = useContractWrite(contract, "burnToken")
  const { mutateAsync: claimSOTY } = useContractWrite(contract, "claimSOTY")

  const burn = async () => {
    try {
      document.getElementById("claim-eligible").style.display = "none"
      document.getElementById("reload-load").innerHTML = `BURNING` + `<span class="text-loading">...</span>`
      document.getElementById("reload-load").classList.add("disabled-header")
      document.getElementById("go-load").style.display = "none"
      document.getElementById("delegate").style.display = "none"
      myDirectory.current.style.display = "none"
      const data = await burnToken({ args: [arrayToSend] });
      document.getElementById("reload-load").innerHTML = "SUCCESS!"
      console.info("contract call successs", data);
      document.getElementById("go-tx").style.display = null
      document.getElementById("go-tx").href = "https://etherscan.io/tx/" + data.receipt.transactionHash;
      document.getElementById("go-load").style.display = null
      document.getElementById("reset").innerHTML = "BURN AGAIN"
      getStats(address)
    } catch (err) {
      console.error("contract call failure", err);
      document.getElementById("reload-load").innerHTML = "ERROR. INSUFFICIENT FUNDS"
      document.getElementById("go-load").style.display = null
    }
  }

  const claim = async () => {
    try {
      var walletClaim;

      if (delegating) {
        walletClaim = delegateWallet;
      }
      else {
        walletClaim = address;
      }

      document.getElementById("claim-eligible").innerHTML = `CLAIMING` + `<span class="text-loading">...</span>`
      document.getElementById("claim-eligible").classList.add("disabled-header")
      document.getElementById("delegate").style.display = "none"
      myDirectory.current.style.display = "none"
      const data = await claimSOTY({ args: [walletClaim, voucher, delegating] });
      document.getElementById("claim-eligible").innerHTML = "SUCCESS!"
      console.info("contract call successs", data);
      document.getElementById("go-tx-claim").style.display = null
      document.getElementById("go-tx-claim").href = "https://etherscan.io/tx/" + data.receipt.transactionHash;
      document.getElementById("go-load-claim").style.display = null
    } catch (err) {
      console.error("contract call failure", err);
      document.getElementById("claim-eligible").innerHTML = "ERROR. INSUFFICIENT FUNDS"
      document.getElementById("go-load-claim").style.display = null
    }
  }

  function delay(n) {
    return new Promise(function (resolve) {
      setTimeout(resolve, n * 500);
    });
  }

  var maxBurn = 50;

  async function getStats(address) {
    const RPC = "https://eth-mainnet.g.alchemy.com/v2/9_Q3je9mpUl1QWRtnreAunvGrQWMC1yw";
    const provider = new ethers.providers.JsonRpcProvider(RPC);

    const burn = new ethers.Contract(burnContract.address, burnContract.abi, provider);
    const og = new ethers.Contract(ogContract.address, ogContract.abi, provider);

    var mintedRegular = await burn.totalMintedRegular(address);
    var mintedSOTY = await burn.mintedSOTY(address);
    var bal = await og.balanceOf(address);

    var mintedReg = await burn.regularMinted()
    var mintedSOT = await burn.SOTYMinted()

    document.getElementById("total-burned-regular").innerHTML = mintedReg + "/250";
    document.getElementById("total-burned-regular").classList.remove("text-loading")
    document.getElementById("total-soty").innerHTML = mintedSOT + "/25";
    document.getElementById("total-soty").classList.remove("text-loading")

    var regular = Number(BigInt(mintedRegular))

    document.getElementById("total-burned").innerHTML = mintedRegular;

    let avail = maxBurn - regular;

    setAvailable(avail);

    if (avail > bal) {
      document.getElementById("total-available").innerHTML = bal;
    }
    else {
      document.getElementById("total-available").innerHTML = avail;
    }

    document.getElementById("total-burned").classList.remove("text-loading")
    document.getElementById("total-available").classList.remove("text-loading")

    var lookup = address.toLowerCase()

    var mapping = soty[lookup];

    if (!mintedSOTY) {
      if (mapping !== undefined) {
        setVoucher(mapping);
        document.getElementById("claim-eligible").style.display = null;
      }
    }

    if (mintedReg >= 250) {
      myDirectory.current.innerHTML = "Collection is Sold Out"
      myDirectory.current.classList.add("px-3")
      myDirectory.current.style.color = "red"
    }

    if (mintedSOT >= 25) {
      document.getElementById("claim-eligible").style.display = "none";
    }

    if (mintedReg >= 250 && mintedSOT >= 25) {
      document.getElementById("delegate").style.display = "none";
    }


  }




  return (
    <main className="page-wrapper">

      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src="90s.png" width={75} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <a href="https://twitter.com/90s_kids_club" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
                <a href="https://discord.com/invite/90skids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
                <a href="https://opensea.io/collection/90skidsxbrothermerle" target="_blank" className="btn btn-secondary mx-1" ><img style={{ height: "20px" }} src={`${isHovered ? 'os_hover.ea60ed8f.png' : 'opensea.png'}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} /></a>

              </ul>
            </div>
            <div className="offcanvas-header border-top">

              <ConnectWallet
                theme="dark"
                btnTitle="Connect Wallet"
              />

            </div>
          </div>
          <ConnectWallet
            theme="dark"
            btnTitle="Connect Wallet"
          />

        </div>
      </header>

      <section id="module" className="mint-page-war" >



        <section className="container d-md-flex align-items-center justify-content-between pb-3 py-5">


          <nav className="container mint-module">

            <button type="button" id="reload-load" onClick={burn} style={{ display: "none" }} className="btn burn-button btn-sm fs-sm rounded"></button>

            <a href="#" id="go-tx" style={{ display: "none" }} target="_blank"><button type="button" className="btn burn-button-reset btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginLeft: "30px" }} >VIEW TRANSACTION</button></a>

            <a onClick={reload} id="go-load" style={{ display: "none" }} target="_blank"><button type="button" id="reset" className="btn burn-button-reset btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginLeft: "30px" }} >RESET</button></a>


            <a href="#" id="go-tx-claim" style={{ display: "none" }} target="_blank"><button type="button" className="btn burn-button-reset btn-sm fs-sm rounded d-lg-inline-flex" >VIEW TRANSACTION</button></a>

            <a onClick={reload} id="go-load-claim" style={{ display: "none" }} target="_blank"><button type="button" id="reset-claim" className="btn burn-button-reset btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginLeft: "30px" }} >RESET</button></a>




            <div>

              <ConnectWallet
                theme="dark"
                btnTitle="Launch Burn"
                style={{ marginTop: "30px" }}
              />
              <div id="delegate" style={{ display: "none", marginTop: "15px" }}> <DelegateCashButton
                label="Select Delegate Wallet"
                connectedWallet={address}
                theme='dark'
                rpcUrl="https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
                rounded={true}
                onButtonClick={event => console.log(event.detail)}
                onWalletSelect={event => checkDelegate(event.detail)}
              />
              </div>
              <div id="show-stats" style={{ display: "none" }}>
                <h5 style={{ color: "white", marginTop: "25px" }}>Total Burned: <span id="total-burned-regular" className="text-loading">...</span></h5>
                <h5 style={{ color: "white" }}>Total Claimed SOTY: <span id="total-soty" className="text-loading">...</span></h5>
                <p style={{ color: "white", marginTop: "25px" }}>Total Assets: <span id="total" className="text-loading">...</span></p>
                <p style={{ color: "white" }}>Total Burned: <span id="total-burned" className="text-loading">...</span></p>
                <p style={{ color: "white" }}>Total Available to Burn: <span id="total-available" className="text-loading">...</span></p>
                <button type="button" id="claim-eligible" onClick={claim} style={{ display: "none", marginBottom: "15px" }} className="btn burn-button btn-sm fs-sm rounded">Claim (SOTY)</button>
                <p id="toomany" style={{ color: "red", display: "none", backgroundColor: "white" }}>&nbsp;You can only burn 50 tokens for this collection!</p>
              </div>
            </div>

            <section ref={myDirectory} className="row row-cols-2 row-cols-sm-6 py-3">



              {data.map((item, index) => (
                <div key={index} className="py-1">
                  <img className={item.clicked ? "btn btn-after bg-secondary btn-sm fs-sm rounded d-lg-inline-flex py-1" : "btn btn-before bg-secondary btn-sm fs-sm rounded d-lg-inline-flex py-1"} src={`https://90skidspublic.s3.us-east-2.amazonaws.com/${item.id}.png`} onClick={() => handleClick(item)}></img>
                  <p className="text-center">#{item.id}</p>
                </div>
              ))}
            </section>

          </nav>

        </section>
      </section>

    </main>


  );
}

export default App;