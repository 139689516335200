import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Index from './Components/Index';
import Burn from './Components/burn';
import Mag from './Components/mag';
import "./App.css"

function App() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/mag" element={<Mag />} />
        <Route path="/burn" element={< Burn />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
  );
}
export default App;