import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { ethers } from "ethers"
import axios from 'axios';


function App() {

  /* global BigInt */

  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://shop.ninetiesentertainment.com/";
    }, 1000); // 1000 milliseconds delay
  
    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);
  
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredShop, setIsHoveredShop] = useState(false);
  const [isHoveredProject, setIsHoveredProject] = useState(false);
  const [isHoveredProjectBurn, setIsHoveredProjectBurn] = useState(false);
  const [isHoveredBurn, setIsHoveredBurn] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleMouseEnterShop = () => {
    setIsHoveredShop(true);
  }

  const handleMouseLeaveShop = () => {
    setIsHoveredShop(false);
  }

  const handleMouseEnterProject = () => {
    setIsHoveredProject(true);
  }

  const handleMouseLeaveProject = () => {
    setIsHoveredProject(false);
  }

  const handleMouseEnterProjectBurn = () => {
    setIsHoveredProjectBurn(true);
  }

  const handleMouseLeaveProjectBurn = () => {
    setIsHoveredProjectBurn(false);
  }

  const handleMouseEnterBurn = () => {
    setIsHoveredBurn(true);
  }

  const handleMouseLeaveBurn = () => {
    setIsHoveredBurn(false);
  }

  return (
  <main className="page-wrapper">
{/* 
<header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
      <div className="container px-3">
        <a href="/" className="navbar-brand pe-3">
          <img src="90s.png" width={75} alt="Logo" />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">


            </ul>
          </div>
          <div className="offcanvas-header border-top">
          <a href="https://twitter.com/90s_kids_club" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
          <a href="https://discord.com/invite/90skids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
          <a href="https://www.instagram.com/nineties.entertainment/" target="_blank" className="btn btn-icon btn-secondary btn-instagram d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-instagram"></i></a>
          <a href="https://opensea.io/collection/90s-kids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><img src="opensea.png"/></a>
          </div>      
        </div> 

          <a href="https://twitter.com/90s_kids_club" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
          <a href="https://discord.com/invite/90skids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
          <a href="https://www.instagram.com/nineties.entertainment/" target="_blank" className="btn btn-icon btn-secondary btn-instagram d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-instagram"></i></a>
          <a href="https://opensea.io/collection/90s-kids" target="_blank" className="btn btn-secondary mx-1" ><img  style={{height:"20px"}}  src={`${isHovered ? 'os_hover.ea60ed8f.png' : 'opensea.png'}`} onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}/></a>
    
      </div>
    </header> */}


    <div className="image-wrapper-new">
    <img src="90s.png" width={250} alt="Logo" />
        {/* <div className="image-container big">
        <a href="https://shop.ninetiesentertainment.com/" >
          <img src={`${isHoveredShop ? 'shop_color.png' : 'shop_bw.png'}`} onMouseEnter={handleMouseEnterShop}  onMouseLeave={handleMouseLeaveShop} />
          <div className="overlay">
            <img src="shop.png"/>
          </div>
          </a>
        </div>
        <div className="image-container big">
        <a href="/mag" >
          <img src={`${isHoveredProject ? 'mag_color.png' : 'mag_bw.png'}`} onMouseEnter={handleMouseEnterProject}  onMouseLeave={handleMouseLeaveProject} />
          <div className="overlay">
            <img src="mag.png"/>
          </div>
          </a>
        </div>
        <div className="image-container big">
        <a href="/burn" >
          <img src={`${isHoveredBurn ? 'burn_color.png' : 'burn_bw.png'}`} onMouseEnter={handleMouseEnterBurn}  onMouseLeave={handleMouseLeaveBurn} />
          <div className="overlay">
          <img src="burn.png"/>
          </div>
          </a>
        </div> */}
      </div>


</main>

  );
}

export default App;