import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';


import { ethers } from "ethers"

import axios from 'axios';


function App() {



  /* global BigInt */

  const navigate = useNavigate();

  
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredShop, setIsHoveredShop] = useState(false);
  const [isHoveredProject, setIsHoveredProject] = useState(false);
  const [isHoveredBurn, setIsHoveredBurn] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleMouseEnterShop = () => {
    setIsHoveredShop(true);
  }

  const handleMouseLeaveShop = () => {
    setIsHoveredShop(false);
  }

  const handleMouseEnterProject = () => {
    setIsHoveredProject(true);
  }

  const handleMouseLeaveProject = () => {
    setIsHoveredProject(false);
  }

  const handleMouseEnterBurn = () => {
    setIsHoveredBurn(true);
  }

  const handleMouseLeaveBurn = () => {
    setIsHoveredBurn(false);
  }

  return (
  <main className="page-wrapper">

<header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
      <div className="container px-3">
        <a href="/" className="navbar-brand pe-3">
          <img src="90s.png" width={75} alt="Logo" />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">


            </ul>
          </div>
          <div className="offcanvas-header border-top">
          <a href="https://twitter.com/90s_kids_club" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
          <a href="https://discord.com/invite/90skids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
          <a href="https://www.instagram.com/nineties.entertainment/" target="_blank" className="btn btn-icon btn-secondary btn-instagram d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-instagram"></i></a>
          <a href="https://opensea.io/collection/90s-kids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><img src="opensea.png"/></a>
          </div>      
        </div> 

          <a href="https://twitter.com/90s_kids_club" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
          <a href="https://discord.com/invite/90skids" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
          <a href="https://www.instagram.com/nineties.entertainment/" target="_blank" className="btn btn-icon btn-secondary btn-instagram d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-instagram"></i></a>
          <a href="https://opensea.io/collection/90s-kids" target="_blank" className="btn btn-secondary mx-1" ><img  style={{height:"20px"}}  src={`${isHovered ? 'os_hover.ea60ed8f.png' : 'opensea.png'}`} onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}/></a>
    
      </div>
    </header>

    <section id="module" >
    <iframe
        title="Iframe"
        src="https://main.d14suixwh59s11.amplifyapp.com/flip"
        style={{ width: '100%', height: '88vh', border: 'none' }}
      ></iframe>

     </section>

</main>


  );
}

export default App;