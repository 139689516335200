import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThirdwebProvider } from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ThirdwebProvider
      activeChain="ethereum"
      clientId="e8eb5149cc4a9defeb07ccc908376ee4"
      sdkOptions={{
        gatewayUrls: ["https://ninetiesentertainment.myfilebase.com/ipfs/"],
      }}
    >
      <App />
    </ThirdwebProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
